import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SingleSignOnService {
  private msLoggedInUserDetailsSubject = new BehaviorSubject({ userEmail: '', isLoggedIn: false, accessToken: '' });

  constructor(
    private _msBroadcastService: MsalBroadcastService,
    private _msAuthService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private _toastr: ToastrService,
  ) {}

  get msSsoAuthInProgressSubject() {
    return this._msBroadcastService.inProgress$;
  }

  get msSsoAuthMsalSubject() {
    return this._msBroadcastService.msalSubject$;
  }

  setLoggedInUserDetails(userDetails: { userEmail: string; isLoggedIn: boolean; accessToken: string }) {
    this.msLoggedInUserDetailsSubject.next(userDetails);
  }

  get msLoggedInUserDetails() {
    return this.msLoggedInUserDetailsSubject.asObservable();
  }

  isUserLoggedIn(): boolean {
    return this._msAuthService.instance.getActiveAccount() !== null;
  }

  msLogin() {
    this._msAuthService
      .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest) // Use loginPopup for a popup window
      .subscribe({
        next: this.handleLoginPopupSuccess.bind(this),
        error: this.handleLoginPopupFailure.bind(this),
      });
  }

  handleLoginPopupSuccess(result: AuthenticationResult) {
    this._msAuthService.instance.setActiveAccount(result.account);
    this.setLoggedInUserDetails({
      userEmail: result.account.username,
      isLoggedIn: true,
      accessToken: result.accessToken,
    });
  }

  handleLoginPopupFailure() {
    this._toastr.error('There are some issues login with your Microsoft account. Please try again later.');
  }

  msLogout() {
    this.setLoggedInUserDetails({
      userEmail: '',
      isLoggedIn: false,
      accessToken: '',
    });
  }
}
