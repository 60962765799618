import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as crypto from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIUrls } from '../enums/apiUrls';
import { OTPResponse } from '../interfaces/Auth/OTPResponse';
import { ApiService } from './api.service';
import { SingleSignOnService } from './single-sign-on.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isSiteUnderMaintenance: boolean = false;
  private siteUnderMaintenanceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  siteUnderMaintenanceObservable: Observable<boolean> = this.siteUnderMaintenanceSubject.asObservable();

  constructor(
    private router: Router,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _ssoService: SingleSignOnService,
  ) {}

  get hubUrl(): string {
    return `${environment.apiUrl}${APIUrls.HubUrl}`;
  }

  getUserInfo() {
    const data: string = this.get('encryptedValue') ?? '';
    const token: string = this.get('accessToken') ?? '';
    return data && token ? JSON.parse(crypto.AES.decrypt(data, token).toString(crypto.enc.Utf8)) : null;
  }

  userInRole(role: string): boolean {
    const user = this.getUserInfo();
    return user && user.roleName === role;
  }

  checkIfLocalStorage(): boolean | null {
    const isLocalStorage: string | null = localStorage.getItem('isRM');
    const isLocalStorageExist = isLocalStorage === null || isLocalStorage === undefined ? null : false;
    return isLocalStorage === 'true' ? true : isLocalStorageExist;
  }

  set(key: string, value: string, data?: any) {
    let encryptedValue: string = '';
    key == 'accessToken' && data && (encryptedValue = crypto.AES.encrypt(JSON.stringify(data), value).toString());
    localStorage.setItem(key, value);
    encryptedValue && localStorage.setItem('encryptedValue', encryptedValue);
  }

  //get from storage
  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    if (key == 'isRM') {
      localStorage.clear();
    } else if (this.checkIfLocalStorage()) {
      localStorage.removeItem(key);
    }
  }

  isLoggedIn(): boolean {
    const isLocal = this.checkIfLocalStorage();
    if (isLocal === null) {
      this.logout();
    }
    if (isLocal) {
      const currentUser = localStorage.getItem('accessToken');
      if (!currentUser) {
        this.logout();
      }
    }
    return true;
  }

  getCurrentUrl() {
    return this.router?.routerState?.snapshot?.url;
  }

  checkForClientUser(): boolean {
    return this.getCurrentUrl()?.includes('/client/');
  }

  checkIfSiteUnderMaintenance(): boolean {
    return this.getCurrentUrl()?.includes('/maintenance');
  }

  logout() {
    this._modalService.dismissAll();
    const isClient = (this.get('isClient') && this.get('isClient') !== 'false') || this.checkForClientUser();
    if (this._ssoService.isUserLoggedIn() && !isClient) {
      this._ssoService.msLogout();
    }
    localStorage.clear();
    this.router.navigate(isClient ? ['client', 'login'] : ['login'], { replaceUrl: true });
  }

  setIsSiteUnderMaintenance(performingMaintenance: boolean = false) {
    this.isSiteUnderMaintenance = performingMaintenance;
    this.siteUnderMaintenanceSubject.next(this.isSiteUnderMaintenance);
  }

  get isUnderMaintenance() {
    return this.isSiteUnderMaintenance;
  }

  underMaintenance() {
    if (!this.checkIfSiteUnderMaintenance()) {
      this.router.navigate(['maintenance'], { replaceUrl: true });
    }
  }

  validateMsLoggedInUser(token: string): Observable<OTPResponse> {
    return this._apiService.get(APIUrls.ValidateJwtToken, null, {
      headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
    });
  }

  logoutAndRedirectToLogin = (toAdmin: boolean = true) => {
    localStorage.clear();
    toAdmin ? this.navigateTo(['login']) : this.navigateTo(['client', 'login']);
  };

  navigateTo = (route: string[], state?: any) => {
    state ? this.router.navigate([...route], { state }) : this.router.navigate([...route]);
  };
}
