import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private _httpClient: HttpClient,
    private _httpClientWithBackEnd: HttpClient,
    private _httpBackend: HttpBackend,
  ) {
    this._httpClientWithBackEnd = new HttpClient(this._httpBackend);
  }

  prepareHttpParameters(params: any): HttpParams {
    return new HttpParams({ fromObject: params });
  }

  prepareHttpHeaders(isContentTypeJSON: boolean = false): HttpHeaders {
    if (isContentTypeJSON) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }
    return new HttpHeaders();
  }

  getUrl(url: string): string {
    return `${environment.apiUrl}/${url}`;
  }

  get<T>(url: string, parameters: any = null, options: any = null, isExternalUrl: boolean = false): Observable<any> {
    let queryParams: HttpParams = new HttpParams();
    parameters && (queryParams = this.prepareHttpParameters(parameters));
    return this._httpClient.get<T>(isExternalUrl ? url : this.getUrl(url), { params: queryParams, ...options });
  }

  post<T>(url: string, parameters: any, options: any = {}, isExternalUrl: boolean = false): Observable<any> {
    return this._httpClient.post<T>(isExternalUrl ? url : this.getUrl(url), parameters, options);
  }

  put<T>(url: string, parameters: any, options: any = {}, isExternalUrl: boolean = false): Observable<any> {
    return this._httpClient.put<T>(isExternalUrl ? url : this.getUrl(url), parameters, options);
  }

  postWithBackend<T>(url: string, parameters: any, options: any = {}, isExternalUrl: boolean = false): Observable<any> {
    return this._httpClientWithBackEnd.post<T>(isExternalUrl ? url : this.getUrl(url), parameters, options);
  }

  delete<T>(url: string, parameters: any = null, options: any = null, isExternalUrl: boolean = false): Observable<any> {
    let queryParams: HttpParams = new HttpParams();
    parameters && (queryParams = this.prepareHttpParameters(parameters));
    return this._httpClient.delete<T>(isExternalUrl ? url : this.getUrl(url), { params: queryParams, ...options });
  }
}
